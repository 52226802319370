<template>
  <div>
    <div v-if="UIController.error.error404" class="error-container">
      <!-- <h3 class="error">404 Not Found</h3> -->
      <div class="card-base">
        <div class="card-base__header">
          <div class="avatar">
            <img :src="require('@/assets/SWLOGO.png')" class="card__img" />
          </div>
          <h4 style="text-align:center;">Driving Innovation and Growth Unlimited</h4>
          <p class="description">
            Making software development service affordable, scalable, and easily
            accessible for everyone.
          </p>
        </div>
        <div class="card__body">
          <div class="info__pill phone">
            <div class="info__logo">
              <img
                :src="require('@/assets/web_black.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a
                href="https://digu.one"
                class="base-link"
                target="_blank"
              >
                <span>Website</span>
              </a>
            </div>
          </div>
          <div class="info__pill phone">
            <div class="info__logo">
              <img
                :src="require('@/assets/calendar.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a
                href="https://digu.one/events"
                class="base-link"
                target="_blank"
              >
                <span>Events</span>
              </a>
            </div>
          </div>
          <div class="info__pill phone">
            <div class="info__logo">
              <img
                :src="require('@/assets/certificate-solid.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a
                href="https://digu.one/trainings-and-certifications"
                class="base-link"
                target="_blank"
              >
                <span>Training & Certifications</span>
              </a>
            </div>
          </div>
          <div class="info__pill phone">
            <div class="info__logo">
              <img
                :src="require('@/assets/person_black.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a
                href="https://digu.one/career"
                class="base-link"
                target="_blank"
              >
                <span>Join Our Talent Pool</span>
              </a>
            </div>
          </div>
          <div class="info__pill phone">
            <div class="info__logo">
              <img
                :src="require('@/assets/discord.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a
                href="https://discord.gg/zzZj8Qzeu8"
                class="base-link"
                target="_blank"
              >
                <span>Discord</span>
              </a>
            </div>
          </div>
          <div class="info__pill phone">
            <div class="info__logo">
              <img
                :src="require('@/assets/linkedin-in_black.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a
                href="https://www.linkedin.com/company/digu-official/"
                class="base-link"
                target="_blank"
              >
                <span>Linkedin</span>
              </a>
            </div>
          </div>
          <div class="info__pill phone">
            <div class="info__logo">
              <img
                :src="require('@/assets/instagram_black.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a
                href="https://www.instagram.com/digu.official/"
                class="base-link"
                target="_blank"
              >
                <span>Instagram</span>
              </a>
            </div>
          </div>
          <div class="info__pill phone">
            <div class="info__logo">
              <img
                :src="require('@/assets/facebook-f_black.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a
                href="https://www.facebook.com/digu.official/"
                class="base-link"
                target="_blank"
              >
                <span>Facebook</span>
              </a>
            </div>
          </div>
          <div class="info__pill phone">
            <div class="info__logo">
              <img
                :src="require('@/assets/x-twitter_black.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a
                href="https://x.com/digu_official"
                class="base-link"
                target="_blank"
              >
                <span>Twitter</span>
              </a>
            </div>
          </div>
          <div class="info__pill phone">
            <div class="info__logo">
              <img
                :src="require('@/assets/youtube_black.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a
                href="https://www.youtube.com/@digu_official"
                class="base-link"
                target="_blank"
              >
                <span>YouTube</span>
              </a>
            </div>
          </div>
          <!-- <div class="info__pill phone">
            <div class="info__logo">
              <img
                :src="require('@/assets/tiktok_black.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a
                href="https://www.tiktok.com/@smartworkstechnology"
                class="base-link"
                target="_blank"
              >
                <span>TikTok</span>
              </a>
            </div>
          </div> -->
        </div>
        <div class="card__footer">
          <p class="copyright text-white">{{ getCurrentYear }} | DIGU</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="main-card" v-if="UIController.loader.detailsLoaded">
        <div class="card__header">
          <img :src="require('@/assets/SWLOGO.png')" class="card__logo" />
          <div style="padding-top: 1em;" class="header__details">
            <h2 class="card-title">
              <span style="margin-right: 0.5rem">{{
                details.firstName || ''
              }}</span
              ><strong>{{ details.lastName || '' }}</strong>
            </h2>
            <span class="card-sub-title">
              {{ details.designation || '' }}
            </span>
          </div>

          <img
            v-if="details.photoLink"
            :src="`${details.photoLink_}`"
            alt="Image"
            class="card-image"
          />
        </div>

        <div class="card__body">
          <div class="info__pill phone">
            <div class="info__logo">
              <img
                :src="require('@/assets/phone-solid.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a :href="`tel:${details.phone}`" class="info__content link">{{
                details.phone
              }}</a>
            </div>
          </div>
          <div class="info__pill email">
            <div class="info__logo">
              <img
                :src="require('@/assets/at-solid.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a :href="`mailto:${details.email}`" class="info__content link"
                ><span class="mail-id">{{ details.firstName }}</span
                >.<span class="mail-id">{{ details.lastName }}</span></a
              >
            </div>
          </div>
          <div class="info__pill location">
            <div class="info__logo">
              <img
                :src="require('@/assets/location-dot-solid.svg')"
                class="info__logo-img"
              />
            </div>
            <div class="info__text">
              <a
                target="_blank"
                :href="`https://google.com/maps/place/${details.location}`"
                class="info__content link"
                >{{ details.location }}</a
              >
            </div>
          </div>
        </div>
        <div class="card__footer">
          <div class="social__container">
            <a
              href="https://digu.one"
              class="social__link website"
              target="_blank"
            >
              <img
                :src="require('@/assets/globe-solid.svg')"
                alt=""
                class="info__logo-img"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/digu-official"
              class="social__link linkedin"
              target="_blank"
            >
              <img
                :src="require('@/assets/linkedin-in.svg')"
                alt=""
                class="social__image"
              />
            </a>
            <a
              href="https://www.instagram.com/digu.official/"
              class="social__link instagram"
              target="_blank"
            >
              <img
                :src="require('@/assets/instagram.svg')"
                alt=""
                class="social__image"
              />
            </a>
            <a
              href="https://www.facebook.com/digu.official/"
              class="social__link facebook"
              target="_blank"
            >
              <img
                :src="require('@/assets/facebook-f.svg')"
                alt=""
                class="social__image"
              />
            </a>
            <a
              href="https://x.com/digu_official"
              class="social__link x"
              target="_blank"
            >
              <img
                :src="require('@/assets/x-twitter.svg')"
                alt=""
                class="social__image"
              />
            </a>
            <a
              href="https://www.youtube.com/@digu_official"
              class="social__link youtube"
              target="_blank"
            >
              <img
                :src="require('@/assets/youtube.svg')"
                alt=""
                class="social__image"
              />
            </a>
            <!-- <a
              href="https://www.tiktok.com/@smartworkstechnology"
              class="social__link tiktok"
              target="_blank"
            >
              <img
                :src="require('@/assets/tiktok.svg')"
                alt=""
                class="social__image"
              />
            </a> -->
          </div>
          <a class="vcard-button" :href="details.vcardLink_">
            Add to Contact
          </a>
          <p class="copyright">{{ getCurrentYear }} | DIGU</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contactService from './services/contactService';
export default {
  data: () => ({
    details: new Object(),
    UIController: {
      loader: {
        detailsLoaded: false,
      },
      error: {
        error404: false,
      },
    },
  }),
  beforeCreate() {
    document.title = 'DIGU';
  },
  async created() {
    if (!location.pathname === undefined) {
      this.UIController.error.error404 = true;
    }

    const slug = location.pathname;
    this.details = await contactService
      .fetchContact(slug)
      .then((response) => {
        if (!response) {
          this.UIController.error.error404 = true;
        }
        return response;
      })
      .catch((error) => {
        console.error(error);
        this.UIController.error.error404 = true;
      });

    if (this.details) {
      if (this.details.photoLink) {
        this.details.photoLink_ = `${process.env.VUE_APP_CDN_LINK}/${this.details.photoLink}`;
      }
      this.details.vcardLink_ = `${process.env.VUE_APP_CDN_LINK}/${this.details.vcardLink}`;
      document.title = `${this.details.firstName} ${this.details.lastName} | DIGU`;
    }
    this.UIController.loader.detailsLoaded = true;
  },
  computed: {
    getCurrentYear: () => {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.main-card,
.card-base {
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  max-width: 375px;
  /* max-height: 750px; */
  margin-top: 2rem;
  margin-inline: auto;
  border-radius: 10px;
  color: #f5f5f5;
  overflow: hidden;
}

.card-base {
  /* background-color: #2ea3f2 !important; */
  background: url('@/assets/baseBG.webp');
  background-size: cover;
  font-family: Manrope, sans-serif;
  border-radius: 1.75rem;
}

.card-base__header {
  padding: 1rem;
}

.card-base__header .card__img {
  width: 100%;
}

.avatar {
  width: 9rem;
  border-radius: 2rem;
  padding: 0.5rem;
  margin-inline: auto;
  margin-top: 2rem;
}

.card-base__header h3 {
  text-align: center;
  margin-bottom: 0.5rem;
  font-family: Manrope, sans-serif;
}

.card-base__header .description {
  text-align: center;
  font-size: 0.85rem;
  margin-top: 0px;
}

.card__header {
  background: url('assets/vector.svg');
  background-position-y: -1rem;
  padding: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 250px;
}

.card-image {
  width: 128px;
  aspect-ratio: 1;
  border-radius: 20em;
  align-self: end;
  margin-right: 1rem;
}

.card__logo {
  width: 6rem;
  align-self: flex-end;
  margin-right: 1rem;
}

.card-title {
  font-family: 'Manrope';
  font-size: 1.75rem;
  display: flex;
  width: 100%;
  margin-left: 2rem;
  margin-top: 0px;
  margin-bottom: 1rem;
}

.card-title strong {
  font-weight: 700;
}

.card-title span {
  font-weight: 300;
}

.card-sub-title {
  font-family: 'Manrope';
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  width: 60%;
  margin-top: -1rem;
  margin-left: 2rem;
  color: #f5f5f5;
}

.card__body {
  display: grid;
  grid-template-columns: 1fr;
  width: 90%;
  margin: 1rem;
}

.info__pill {
  display: grid;
  grid-template-columns: 1fr 4fr;
  column-gap: 1rem;
  padding: 0.8rem;
  border-radius: 0.8rem;
  background-color: #fff;
  margin-bottom: 0.5rem;
}

.info__logo {
  display: grid;
  align-content: center;
  /* border-right: 1px solid #f0f0f0; */
}

.info__logo-img {
  width: 18px;
  aspect-ratio: 1;
}

.info__text {
  display: grid;
  align-content: center;
}

.info__content {
  font-family: 'Manrope', sans-serif;
  text-decoration: none;
  color: #6d6371;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
}

.info__content.link:hover {
  text-decoration: underline;
}

.card-container {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
}

.vcard {
  width: 100%;
  display: flex;
  justify-content: center;
}

.card__footer {
  width: 90%;
  margin-top: 1rem;
}

.social__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-content: center;
  justify-items: center;
}

.social__link {
  width: 36px;
  height: 36px;
  border-radius: 20px;
  display: grid;
  align-items: center;
  justify-items: center;
}

.social__link.linkedin {
  background-color: #0a66c2;
}

.social__link.website {
  background-color: #2ea3f2;
}

.social__link.instagram {
  background: linear-gradient(135deg, #833ab4, #fd1d1d, #fcb045);
}

.social__link.facebook {
  background: #1877f2;
}

.social__link.x {
  background: #000000;
}

.social__link.youtube {
  background: #ff0000;
}

.social__link.tiktok {
  background: #000;
}

.social__link .social__image {
  width: 20px;
  aspect-ratio: 1;
}

.vcard-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2ea3f2;
  text-decoration: none;
  padding: 15px;
  font-family: 'Manrope';
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #f0f0f0;
  border-radius: 100px;
}

.copyright {
  text-align: center;
  font-size: 0.85rem;
  color: #6d6e71;
  font-family: 'Manrope', sans-serif;
  margin-top: 1.5rem;
}

.error-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.error {
  font-size: 3rem;
  font-family: 'Manrope', sans-serif;
}

.mail-id {
  text-transform: lowercase;
}

.text-white {
  color: #fff !important;
}

.card-base .card__body .info__pill .info__text a {
  text-align: center !important;
  text-decoration: none;
  color: black;
  font-size: 1rem;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.card-base .card__body .info__pill .info__text a:hover {
  color: #2ea3f2;
}

.card-base .card__body .info__pill .info__text {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.card-base .card__body .info__pill {
  text-align: center !important;
  position: relative;
}
</style>
