const fetchContact = async (pathname) => {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_CDN_LINK}/contacts.json`
    ).then((response_) => {
      return response_.json();
    });
    return response.filter((element) => element.slug === pathname)[0];
  } catch (error) {
    console.error(error);
    return false;
  }
};

export default {
  fetchContact,
};
